<template>
  <div id="wrapper" class="our-mission">
    <!-- Navigation -->
    <NavBar fixedNav="true" />

    <!-- Section 1 -->
    <div class="sec-1">
      <div class="row">
        <div class="col-1 col-md-2 col-lg-2"></div>
        <div class="col-12 col-md-8 col-lg-8">
          <div class="row">
            <div class="col-4 my-auto">
              <img class="h3-logo"
                    src="../assets/images/h3_logo_shadow.png"
                    alt="h3-logo" />
            </div>
            <div class="col-8 my-auto">
              <h2>Mission</h2>
              <p>
                Our mission is to create culturally relevant content and
                programs that promote mental and physical health, educational
                achievement, social interaction and personal empowerment.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-9 my-auto">
              <h2>Vision</h2>
              <p>
                Healthy Hip Hop is transforming a generation through hip-hop
                culture, education and cutting-edge technology.
              </p>
            </div>
            <div class="col-3 my-auto text-right">
              <img class="pj-panda"
                   src="../assets/images/pj_w_app.png"
                   alt="pj-panda" />
            </div>
          </div>

          <div class="row">
            <div class="col-3 my-auto">
              <img class="leo-lion"
                   src="../assets/images/leo_w_app.png"
                   alt="leo-lion" />
            </div>
            <div class="col-9 my-auto">
              <h2>Values</h2>
              <p>
                Our organization is focused on maintaining the vibe and energy
                of mainstream hip-hop, but ensures the content is focused on
                teamwork, self-esteem and other positive attributes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 col-lg-2"></div>
      </div>
    </div>

    <!-- Section 2 -->
    <div class="sec-2">
      <div class="row">
        <div class="col-12 col-md-2 col-lg-2"></div>
        <div class="col-12 col-md-8 col-lg-8">
          <div class="row">
            <div class="col">
              <h2 class="text-center">
                Partner with Healthy Hip Hop to bring culture to the classroom!
              </h2>
              <p class="text-content">
                Healthy Hip Hop is bringing culture to the classroom through
                music, mindfulness and motivation! Our platform provides a
                secure network for social interaction, allowing you to reach
                and empower children with culturally responsive content that
                improves engagement and focus.
              </p>
            </div>
          </div>

          <div class="boxes">
            <div class="row">
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0 text-center">MUSIC</h3>
              </div>
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0">MINDFULNESS</h3>
              </div>
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0">MOTIVATION</h3>
              </div>
            </div>
          </div>
          <br><br>
          <div class="row">
            <div class="col">
              <h2 class="text-center pt-3">Reasons to Partner</h2>
            </div>
          </div>

          <div class="boxes">
            <div class="row">
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0 text-center">Community Building</h3>
              </div>
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0">Positive Influence</h3>
              </div>
              <div class="col-12 col-lg-4">
                <h3 class="box mb-0">Brand Awareness</h3>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-2 col-lg-2"></div>
      </div>
    </div>

    <!-- Section 3 -->
    <div class="sec-3">
      <div class="row">
        <div class="col-12 col-md-2 col-lg-2"></div>
        <div class="col-12 col-md-8 col-lg-8">
          <h2 class="text-center pb-4">Contact Us</h2>
          
          <b-form class="contact-form"
                  @submit="sendContactForm">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <b-form-group class="label-form"
                              id="name-input"
                              label="Name*"
                              label-for="name">
                  <b-form-input id="name"
                                type="text"
                                v-model="form.name"
                                autocomplete="off"
                                @input="$v.form.name.$touch()"
                                :state="!$v.form.name.$error"
                  ></b-form-input>
                  <b-form-invalid-feedback id="name">
                    <span v-if="!$v.form.name.required">
                      Name is required.
                    </span>
                    <span v-if="!$v.form.name.name && $v.form.name.required">
                      Contains invalid characters.
                    </span>
                    <span v-if="!$v.form.name.minLength &&
                      $v.form.name.required && $v.form.name.name">
                      Minimum length must be 2 characters.
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <b-form-group class="label-form"
                              id="title-input"
                              label="Title"
                              label-for="title">
                  <b-form-input id="title"
                                type="text"
                                v-model="form.title"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <b-form-group class="label-form"
                              id="email-input"
                              label="Email*"
                              label-for="email">
                  <b-form-input id="email"
                                type="email"
                                v-model="form.email"
                                autocomplete="off"
                                @input="$v.form.email.$touch()"
                                :state="!$v.form.email.$error"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email">
                    <span v-if="!$v.form.email.required">
                      Email is required.
                    </span>
                    <span v-if="!$v.form.email.email && $v.form.email.required">
                      Not a valid Email.
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <b-form-group class="label-form"
                              id="phone-input"
                              label="Phone"
                              label-for="Phone">
                  <b-form-input id="phone"
                                type="tel"
                                v-model="form.phone"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group class="label-form"
                              id="message-input"
                              label="Message*"
                              label-for="message">
                  <b-form-textarea id="message"
                                   rows="5"
                                   v-model="form.message"
                                   autocomplete="off"
                                   @input="$v.form.message.$touch()"
                                   :state="!$v.form.message.$error"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="message">
                    <span v-if="!$v.form.message.required">
                      Message is required.
                    </span>
                    <span v-if="!$v.form.message.minLength &&
                      $v.form.message.required">
                      Minimum length must be 2 characters.
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>

                <div class="text-center">
                  <p class="required-text">* Required fields</p>
                  <b-button class="btn btn-danger btn-h3"
                            type="submit"
                            :disabled="$v.form.$invalid">
                    Send
                  </b-button>
                </div>
              </div>
            </div>
          </b-form>
        </div>
        <div class="col-12 col-md-2 col-lg-2"></div>
      </div>
    </div>

    <!-- Footer -->
    <FooterBar/>

    <!-- Contact Form -->
    <ContactButton visibleFooter="true"/>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const NavBar = () =>  import('@/components/shared/Navbar.vue');
  const FooterBar = () => import('@/components/shared/Footerbar.vue');
  const ContactButton = () => import('@/components/shared/ContactButton.vue');
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  const name = helpers.regex('name', /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/);

  import { required, email, minLength, helpers } from
    'vuelidate/lib/validators';
  
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name : 'OurMisson',
    data() {
      return {
        form : {
          name    : null,
          title   : null,
          email   : null,
          phone   : null,
          message : null,
        },
        isLoading : false,
      }
    },
    components : {
      NavBar,
      FooterBar,
      ContactButton,
      Loading,
      RainbowLoader,
    },
    methods : {
      sendContactForm : function(e) {
        e.preventDefault();

        this.isLoading = true;

        this.$http.post('/auth/contactEmail', {
          name    : this.form.name,
          title   : this.form.title,
          email   : this.form.email,
          phone   : this.form.phone,
          message : this.form.message,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Thank you for contacting us.',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
          this.$nextTick(() => {
            this.form.name = null;
            this.form.title = null;
            this.form.email = null;
            this.form.phone = null;
            this.form.message = null;
            this.$v.form.$reset();
          });
        });
      },
    },
    validations : {
      form : {
        name    : { required, name, minLength : minLength(2) },
        title   : {},
        email   : { required, email },
        phone   : {},
        message : { required, minLength : minLength(2) },
      },
    },
    mounted () {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'our-mission',
        'platform' : 'website',
      });
    },
  }
</script>


<style lang="scss" scoped>
  @import '../assets/scss/components/our-mission';
</style>
